import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"

const PlaylistItem = function ({ item }) {
  return (
    <li>
      <div className="text-center">
        <GatsbyImage
          className="rounded mb-3"
          image={item.cover.childImageSharp.gatsbyImageData}
          alt={`${item.artist} - ${item.title}`}
        />
        <div className="font-bold">{item.artist}</div>
        <div>{item.title}</div>
      </div>
    </li>
  )
}

PlaylistItem.propTypes = {
  item: PropTypes.shape({
    artist: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cover: PropTypes.arrayOf.isRequired,
  }).isRequired,
}

export default PlaylistItem
