import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"

import { section, li, bg } from "./Slider.module.css"

const Slider = function ({ items }) {
  const [opacities, setOpacities] = React.useState([])

  const [sliderRef] = useKeenSlider({
    slides: items.length,
    loop: true,
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      setOpacities(newOpacities)
    },
  })

  return (
    <section className={section} ref={sliderRef}>
      <ul>
        {items.map(function (slide) {
          return (
            <li
              className={li}
              style={{
                opacity: opacities[slide.background.id],
              }}
              key={slide.background.id}
            >
              <GatsbyImage
                className={bg}
                image={slide.background.childImageSharp.gatsbyImageData}
                alt="Slide"
              />
              {/* <div className="container">{parse(slide.content)}</div> */}
            </li>
          )
        })}
      </ul>
      {}
    </section>
  )
}

Slider.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      background: PropTypes.string.isRequired,
      content: PropTypes.string,
    })
  ).isRequired,
}

export default Slider
