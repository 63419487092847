import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import parse from "html-react-parser"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Slider from "../components/Slider"
import Card from "../components/Card"
import ButtonLink from "../components/ButtonLink"
import PlaylistItem from "../components/PlaylistItem"

import PresentationAteliers from "../images/video-presentation-ateliers-ensemble-au-micro-one-station.mp4"

const IndexPage = function ({
  data: {
    indexJson,
    flashInfos,
    flashMeteo,
    minuteEmploi,
    allPlaylistItemsJson,
    allRegiePubItemsJson,
    allAgendaItemsJson,
  },
  location,
}) {
  const [datas, setDatas] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`https://api.onestation.fr/onair`)
        const json = await res.json()
        setDatas(json.currentSong)
      } catch (error) {
        console.log(error)
      }
    }

    const id = setInterval(() => {
      fetchData()
    }, 15000)

    fetchData()

    return () => clearInterval(id)
  }, [])

  return (
    <Layout>
      <Seo
        title={indexJson.title}
        description={indexJson.description}
        pathname={location.pathname}
      />

      <Slider items={indexJson.slider} />

      <div className="container">
        <section className="text-center max-w-2xl mx-auto">
          <h2 className="normal-case">{indexJson.radio.title}</h2>
          {parse(indexJson.radio.content)}
          {/* <div className="text-center">
            <ButtonLink item={indexJson.radio.button} />
          </div> */}
          <p>
            Rejoins-nous sur les réseaux sociaux :{` `}
            <a
              className="text-blue-600 ml-2"
              href="https://www.facebook.com/onestation"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fa-stack fa-lg">
                <i className="fas fa-circle fa-stack-2x" />
                <i className="fab fa-facebook-f fa-stack-1x fa-inverse" />
              </span>
            </a>
            <a
              className="text-amber-800"
              href="https://instagram.com/onestationfr"
              target="_blank"
              rel="noreferrer"
            >
              <span className="fa-stack fa-lg">
                <i className="fas fa-circle fa-stack-2x" />
                <i className="fab fa-instagram fa-stack-1x fa-inverse" />
              </span>
            </a>
          </p>
        </section>

        {/* <section>
          <h2>
            <Link to={indexJson.infosMeteo.title.link}>
              {indexJson.infosMeteo.title.label}
            </Link>
          </h2>
          <ul className="grid md:grid-cols-3 gap-5">
            <Card item={flashInfos} />
            <Card item={flashMeteo} />
            <Card
              item={{
                ...minuteEmploi.podcasts[18],
                link: `/minute-emploi`,
              }}
            />
          </ul>
            </section> */}

        <section>
          <h2>
            <Link to={indexJson.playlist.title.link}>
              {indexJson.playlist.title.label}
            </Link>
          </h2>
          <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-5 mb-5">
            <PlaylistItem
              key={uuidv4()}
              item={{ ...datas, cover: allPlaylistItemsJson.nodes[0].cover }}
            />

            {allPlaylistItemsJson.nodes.map(function (item) {
              return <PlaylistItem key={uuidv4()} item={item} />
            })}
          </ul>

          <div className="text-center">
            <ButtonLink item={indexJson.playlist.button} />
          </div>
        </section>

        {/* <section>
          <h2>
            <Link to={indexJson.regiePub.title.link}>
              {indexJson.regiePub.title.label}
            </Link>
          </h2>
          <p className="mb-5">{parse(indexJson.regiePub.content)}</p>
          <ul className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-5">
            {allRegiePubItemsJson.nodes.map(function (item) {
              return <Card key={uuidv4()} item={item} />
            })}
          </ul>
          <div className="text-center">
            <ButtonLink item={indexJson.regiePub.button} />
          </div>
          </section> */}

        <section className="text-center">
          <h2>
            <Link to={indexJson.ensembleAuMicro.title.link}>
              {indexJson.ensembleAuMicro.title.label}
            </Link>
          </h2>
          {parse(indexJson.ensembleAuMicro.content)}

          <div className="text-center max-w-3xl mx-auto">
            <h2 className="normal-case text-center">
              Vidéo de présentation des ateliers
            </h2>

            <video controls className="mb-8">
              <source src={PresentationAteliers} type="video/mp4" />
            </video>
          </div>

          <ButtonLink item={indexJson.ensembleAuMicro.button} />
        </section>

        {/* <section>
          <h2>
            <Link to={indexJson.agenda.title.link}>
              {indexJson.agenda.title.label}
            </Link>
          </h2>
          <ul className="grid md:grid-cols-3 gap-5">
            {allAgendaItemsJson.nodes.map(function (item) {
              return (
                <Card
                  key={uuidv4()}
                  item={{
                    ...item,
                    link: `/agenda`,
                  }}
                />
              )
            })}
          </ul>
        </section>

        <section>
          <h2>{indexJson.social.title}</h2>
        </section> */}
      </div>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default IndexPage

export const query = graphql`
  query IndexPageQuery {
    indexJson {
      title
      description
      slider {
        background {
          id
          childImageSharp {
            gatsbyImageData
          }
        }
        content
      }
      radio {
        title
        content
        button {
          icon
          label
          link
        }
      }
      infosMeteo {
        title {
          label
          link
        }
      }
      playlist {
        title {
          label
          link
        }
        button {
          icon
          label
          link
        }
      }
      regiePub {
        title {
          label
          link
        }
        content
        button {
          icon
          label
          link
        }
      }
      ensembleAuMicro {
        title {
          label
          link
        }
        content
        button {
          icon
          label
          link
        }
      }
      agenda {
        title {
          label
          link
        }
      }
      social {
        title
      }
    }
    flashInfos: infosMeteoItemsJson(category: { eq: "infos" }) {
      title
      link
      banner {
        background {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    flashMeteo: infosMeteoItemsJson(category: { eq: "meteo" }) {
      title
      link
      banner {
        background {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    minuteEmploi: ensembleAuMicroPodcastsJson(
      podcasts: { elemMatch: { category: { eq: "emploi" } } }
    ) {
      podcasts {
        category
        num
        date
        title
        voices
        file
      }
    }
    allPlaylistItemsJson {
      nodes {
        artist
        title
        cover {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allRegiePubItemsJson {
      nodes {
        title
        subTitle
        link
        banner {
          background {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allAgendaItemsJson {
      nodes {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
